import englishData from './data/english.json';
import turkishData from './data/turkish.json';
import germanData from './data/german.json';
import extraData from './data/extra.json';
import AOS from 'aos';

import Swiper from "swiper"
import {Navigation,Pagination} from 'swiper/modules'

// Animate on Scroll
AOS.init({
    duration: 600,
    once: true,
})

function embedDataFromJSON(jsonData: any, containerId: string) {
    const container = document.getElementById(containerId);
    if (container) {

        // JSON verilerini yinele
        for (const sectionKey in jsonData) {
            console.log('Section:', sectionKey);
            const sectionData = jsonData[sectionKey];

            // Section'a özel veri işleme  || sectionKey === 'experience' || sectionKey === 'certificates'
            if(sectionKey === 'header') {
                const headerName = document.getElementById('header-name');

                if (headerName) {
                    headerName.textContent = sectionData.name;
                }
            }else if (sectionKey === 'home') {
                const welcomeMessageElement = document.getElementById('welcome-message');
                if (welcomeMessageElement) {
                    welcomeMessageElement.textContent = sectionData.greeting;
                }

                const homeDescriptionElement = document.getElementById('home-description');
                if (homeDescriptionElement) {
                    homeDescriptionElement.textContent = sectionData.description;
                }

                const homeButtonElement = document.getElementById('home-button');
                if (homeButtonElement) {
                    homeButtonElement.textContent = sectionData.button_text;
                }
            } else if (sectionKey === 'hero_sidebar') {
                const heroSidebarName = document.getElementById('hero-sidebar-name');
                const heroOffcanvasName = document.getElementById('hero-offcanvas-name');
                if (heroSidebarName && heroOffcanvasName) {
                    heroSidebarName.textContent = sectionData.name;
                    heroOffcanvasName.textContent = sectionData.name;
                }

                const heroSidebarDescription = document.getElementById('hero-sidebar-description');
                const heroOffcanvasDescription = document.getElementById('hero-offcanvas-description');
                if (heroSidebarDescription && heroOffcanvasDescription) {
                    heroSidebarDescription.textContent = sectionData.description;
                    heroOffcanvasDescription.textContent = sectionData.description;
                }

                const heroSocialLinks = document.getElementById('hero-sidebar-social-links');
                const heroOffcanvasSocialLinks = document.getElementById('hero-offcanvas-social-links');
                if (heroSocialLinks && heroOffcanvasSocialLinks) {
                    heroSocialLinks.innerHTML = '';
                    heroOffcanvasSocialLinks.innerHTML = '';
                    extraData.socials.forEach(item => {
                        heroSocialLinks.innerHTML += `
                            <a id="hero-sidebar-${item.name}-button" class="social-link mx-3" href="${item.url}"> 
                            <iconify-icon icon="${(extraData.icons.socials as any)[item.name] as string}"></iconify-icon> </a>
                        `;
                        heroOffcanvasSocialLinks.innerHTML += `
                            <a id="hero-offcanvas-${item.name}-button" class="social-link mx-3" href="${item.url}"> 
                            <iconify-icon icon="${(extraData.icons.socials as any)[item.name] as string}"></iconify-icon> </a>
                        `;
                    });
                }

                const heroSidebarResumeButton = document.getElementById('hero-sidebar-resume-button');
                const heroOffcanvasResumeButton = document.getElementById('hero-offcanvas-resume-button');
                if (heroSidebarResumeButton && heroOffcanvasResumeButton) {
                    heroSidebarResumeButton.textContent = sectionData.resume_text;
                    heroOffcanvasResumeButton.textContent = sectionData.resume_text;

                    const closeOffcanvas = () => {
                        const heroOffcanvas = document.getElementsByClassName('offcanvas')[0];
                        const heroOffcanvasBackdrop = document.getElementsByClassName('offcanvas-backdrop')[0];
                        if(heroOffcanvas && heroOffcanvasBackdrop) {
                            heroOffcanvas.classList.remove('show');
                            heroOffcanvasBackdrop.classList.remove('show');
                        }
                    };

                    heroOffcanvasResumeButton.addEventListener('click', closeOffcanvas);
                }


                const modalResumeTr = document.getElementById('modal-resume-tr');
                const modalResumeEn = document.getElementById('modal-resume-en');

                if (modalResumeTr && modalResumeEn) {
                    modalResumeTr.addEventListener("click",() => {window.open(extraData.resume.turkish, '_blank')});
                    modalResumeEn.addEventListener("click",() => {window.open(extraData.resume.english, '_blank')});
                }

            } else if (sectionKey === 'about') {
                const aboutTitle = document.getElementById('about-title');
                if (aboutTitle) {
                    aboutTitle.textContent = sectionData.title;
                }

                const aboutDescription = document.getElementById('about-description');
                if (aboutDescription) {
                    var content = "";
                    sectionData.description.forEach((item: any,index:number) => {
                        content += `<div>${item}</div>`
                        if(index < sectionData.description.length - 1){
                            content += `<br>`
                        }
                    });

                    aboutDescription.innerHTML = content;
                }

                const personalInfoTitle = document.getElementById('personal-info-title');
                const personalInfoData = document.getElementById('personal-info-data');
                if (personalInfoTitle && personalInfoData) {
                    personalInfoTitle.textContent = sectionData.personal_info.title;
                    personalInfoData.innerHTML = `
                    <li>
                        ${Object.keys(sectionData.personal_info.data).map((key: any) => `<li><b>${key}:</b> ${sectionData.personal_info.data[key]}</li>`).join('')}
                    </li>
                    `;
                }

                const additionalInfoTitle = document.getElementById('additional-info-title');
                const additionalInfoData = document.getElementById('additional-info-data');
                if (additionalInfoTitle && additionalInfoData) {
                    additionalInfoTitle.textContent = sectionData.additional_info.title;
                    additionalInfoData.innerHTML = `
                    <li>
                        ${Object.keys(sectionData.additional_info.data).map((key: any) => `<li><b>${key}:</b> ${sectionData.additional_info.data[key]}</li>`).join('')}
                    </li>`;
                }
            } else if (sectionKey === 'skills') {
                const skillTitle = document.getElementById('skill-title');
                if (skillTitle) {
                    skillTitle.textContent = sectionData.title;
                }

                const skillList = document.getElementById('skill-list');
                const dataList = sectionData[sectionKey + '_list'];
                if (skillList) {
                    skillList.innerHTML = '';
                    dataList.forEach((item: any) => {
                        skillList.innerHTML += `
                            <div class="col d-flex align-items-start pb-5">
                                <iconify-icon class="skill-icon" icon="${(extraData.icons.skills as any)[item.title] as string}" width="50"></iconify-icon>
                                <div>
                                    <h4 class="fw-bold mb-0">${item.title}</h4>
                                    <p class="mb-1">${item.description}</p>
                                </div>
                            </div>
                        `
                    });
                }
            }else if (sectionKey === 'experience') {
                const experienceTitle = document.getElementById('experience-title');
                if (experienceTitle) {
                    experienceTitle.textContent = sectionData.title;
                }

                const experienceList = document.getElementById('experience-list');
                const dataList = sectionData[sectionKey + '_list'];
                if (experienceList) {
                    experienceList.innerHTML = '';
                    dataList.forEach((item: any, index: any) => {
                        var dataAlign = index % 2 == 0 ? 'start' : 'end';
                        
                            experienceList.innerHTML += `
                                <div class="row justify-content-${dataAlign}" data-aos="fade-up">
                                    <div class="col-7">
                                        <div class="data-info ">
                                            <p class="meta-date">${item.date} <img src="images/Ellipse 7.webp" alt="image">
                                                <p class="open-description"> ${item.company}</p> </p>
                                            <h3 class="info-title">${item.position}</h3>
                                            <p>${item.description}</p>
                                        </div>

                                    </div>
                                </div>
                            `
                    });
                }
            } else if (sectionKey === 'achievements') {
                const achievementTitle = document.getElementById('achievements-title');
                const achievementData = document.getElementById('achievements-data');
                const dataList = sectionData[sectionKey + '_list'];
                if (achievementTitle && achievementData) {
                    achievementTitle.textContent = sectionData.title;
                    console.log('Achievement Data:', sectionData.achievements_list);
                    achievementData.innerHTML = '';
                    sectionData.achievements_list.forEach((item: any) => {
                        console.log('Achievement Item:', item);
                        achievementData.innerHTML += `
                            <div class="swiper-slide">
                                <div class="card testimonials-inner">
                                    <div class="card-body">
                                        <div class="row" style="margin:0 10%">
                                            <div class="col-md-2" style="display:flex;align-items:center">
                                                <img class="testimonial-image"
                                                    src="images/favorites.webp" alt="image">
                                            </div>
                                            <div class="col-md-10 mt-3">
                                                <p class="card-text mb-2">${item.description}</p>
                                                <div class="testimonial-details">
                                                    <h4 class="name fw-bold m-0">${item.name}</h4>
                                                    <p class="testimonial-location">${item.company} - ${item.date}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        `;
                    });

                    // Swiper'ı başlat
                    new Swiper('.swiper-container', {
                        modules: [Navigation, Pagination],
                        loop: true,
                        pagination: {
                            el: '.swiper-pagination',
                            clickable: true,
                        },
                        navigation: {
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev',
                        },
                        autoplay: {
                            delay: 3000, // Slaytların 3 saniye (3000 ms) sonra otomatik olarak geçiş yapmasını sağlar
                            disableOnInteraction: false, // Kullanıcı etkileşiminden sonra autoplay'in devam etmesini sağlar
                        },
                    });
                }

              
            } else if (sectionKey === 'certificates') {
                const certificatesTitle = document.getElementById('certificates-title');
                if (certificatesTitle) {
                    certificatesTitle.textContent = sectionData.title;
                }

                const certificatesList = document.getElementById('certificates-list');
                const dataList = sectionData[sectionKey + '_list'];
                if (certificatesList) {
                    certificatesList.innerHTML = '';
                    dataList.forEach((item: any, index: any) => {
                        certificatesList.innerHTML += `
                            <li><img src="images/Ellipse 7.webp" alt="image"><b> ${item.title}</b></li>
                            
                        `
                    });
                }


            }
        }
    }
}

window.onload = () => {
    

    const offcanvas = document.getElementsByClassName('offcanvas')[0]; // Offcanvas elementinizi seçin
    const modal = document.getElementById('resumeSelectionModal'); // Modal elementinizi seçin
    const navbar = document.getElementById('navigation-bar'); // Navbar elementinizi seçin

    if(offcanvas && modal && navbar) {
        console.log('Offcanvas:', offcanvas);
        offcanvas.addEventListener('show.bs.offcanvas', () => {
            navbar.classList.add('hidden');
        });

        offcanvas.addEventListener('hidden.bs.offcanvas', () => {
            navbar.classList.remove('hidden');
        });

        modal.addEventListener('show.bs.modal', () => {
            navbar.classList.add('hidden');
        });

        modal.addEventListener('hidden.bs.modal', () => {
            navbar.classList.remove('hidden');
        });
    }

    const locales = ["en-GB",  "de-DE", "tr-TR"];

    function getFlagSrc(countryCode:any) {
        return /^[A-Z]{2}$/.test(countryCode)
            ? `https://hatscripts.github.io/circle-flags/flags/${countryCode.toLowerCase()}.svg`
            : "";
    }

    const dropdownBtn = document.getElementById("dropdown-btn");
    const dropdownContent = document.getElementById("dropdown-content");

    
    function setSelectedLocale(locale:any) {
        const intlLocale = new Intl.Locale(locale);
        const langName = new Intl.DisplayNames([locale], {
            type: "language",
        }).of(intlLocale.language);

        if(!dropdownContent || !dropdownBtn) return;
        dropdownContent.innerHTML = "";

        const otherLocales = locales.filter((loc) => loc !== locale);
        otherLocales.forEach((otherLocale) => {
            const otherIntlLocale = new Intl.Locale(otherLocale);
            const otherLangName = new Intl.DisplayNames([otherLocale], {
                type: "language",
            }).of(otherIntlLocale.language);

            const listEl = document.createElement("li");
            listEl.innerHTML = `<img src="${getFlagSrc(
                otherIntlLocale.region
            )}" />${otherLangName}`;
            listEl.value = locales.indexOf(otherLocale);
            listEl.addEventListener("mousedown", function () {
                setSelectedLocale(otherLocale);
                if(otherLocale === 'en-GB') {
                    embedDataFromJSON(englishData, 'content-container');
                }else if(otherLocale === 'tr-TR') {
                    embedDataFromJSON(turkishData, 'content-container');
                }else if(otherLocale === 'de-DE') {
                    embedDataFromJSON(germanData, 'content-container');
                }
            });
            dropdownContent.appendChild(listEl);
        });

        dropdownBtn.innerHTML = `<img src="${getFlagSrc(
            intlLocale.region
        )}" />${langName}<span class="arrow-down"></span>`;
    }

    setSelectedLocale(locales[0]);
    const browserLang = new Intl.Locale(navigator.language).language;
    console.log('Browser Lang:', browserLang);
    
    for (const locale of locales) {
        const localeLang = new Intl.Locale(locale).language;
        if (localeLang === browserLang) {
            if(localeLang === 'en') {
                embedDataFromJSON(englishData, 'content-container');
            }else if(localeLang === 'tr') {
                embedDataFromJSON(turkishData, 'content-container');
            }else if(localeLang === 'de') {
                embedDataFromJSON(germanData, 'content-container');
            }

            setSelectedLocale(locale);
        }
    }
}